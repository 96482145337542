.site-nav {
  display: flex;
  align-items: center;
  > *:not(:last-child), > * > *:not(:last-child) {
      padding-right: $one-unit * 2;
  }
}

.site-menu {
  list-style: none;
  display: flex;

  &__item {

  }

  &__anchor {
    font-size: 22px;
    padding: ($one-unit * 0.25) 0; // "UX padding" - bigger hover/touch field
    text-decoration: none;
    box-shadow: 0 3px 0 0 $secondary-color;
  }

  .is-active {
    a {
      box-shadow: 0 3px 0 0 currentColor; 
      @include lvl-transition();
    }

    &:hover {
      a { 
        box-shadow: 0 3px 0 0 currentColor; 
        transition: none;
      }
    }

  }

  :hover {
    a {
      box-shadow: 0 3px 0 0 lighten($secondary-color, 6%);
      @include lvl-transition();
    }

  }

}