.cell-content.success-indicator {
  width: 30px;
  height: 30px;
  background-color: lightgreen;
}

.cell-content.success-indicator.failure {
  width: 30px;
  height: 30px;
  background-color: red;
}

.inline-list span {
  display: inline-block;
  padding: 4px 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
  background-color: #f7f8f9;
  margin-right: 1rem;
}

.lvl-tm .tm-table__cell.flexible {
  white-space: normal;
}

.lvl-tm .tm-table__row.plain:hover .cell-content {
  box-shadow: none;
  display: inherit;
}

.lvl-tm .tm-table__row.plain:hover .cell-content.success-indicator {
  display: block !important;
}

.lvl-tm
  .tm-table__row.plain:nth-of-type(odd):hover
  .cell-content.success-indicator {
  background-image: inherit;
}

.ukraine-images {
  display: flex;
  grid-gap: 2rem;
  > div {
    width: 400px;
    height: auto;
  }
}
