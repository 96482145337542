// Settings
$table-odd-background: $gray-lightest;
$table-even-background: $white;
$table-border-width: 8px;

// Some reset, should probably be removed out of here and into global
.tm-table {
  width: 100%;
  a {
    color: $primary-color;
    text-decoration: none;
  }
}

// Styles
.tm-table {
  display: flex;
  flex-direction: column;

  &__header-row {
    display: flex;
    font-weight: bold;
    position: relative;

    .-sortable { // cell
      overflow: visible;
      cursor: pointer;
      position: relative;
      @include lvl-transition();
      @extend .noselect;

      .cell-content {
        box-shadow: 0 2px 0 0 currentColor;

        &:after {
          position: absolute;
          top: 18px; // temp, should be dynamic
          padding-left: 6px;
          @extend .icon;
          font-size: 10px;
          // content: "\f021";
        }
      }

      &:hover {
        color: $white; // Can possibli be removed..?
        background: $primary-color;
        @include lvl-transition();
        .cell-content {
          color: $white;
          box-shadow: 0 2px 0 0 rgba(0,0,0,0);
        }
      }

    }

    .is-order-asc {
      .cell-content {
        &:after {
          content: "\f094";
        }
      }
    }

    .is-order-desc {
      .cell-content {
        &:after {
          content: "\f093";
        }
      }
    }

  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__row {
    display: flex;
    background: $table-even-background; // even
    @include lvl-transition();
    box-shadow: inset $table-border-width 0 0 0 rgba(0,0,0,0);
    position: relative;

    &:nth-of-type(odd) {
      background: $table-odd-background;

      &:hover {
        .cell-content {
          background-image:
            linear-gradient(
              to left,
              rgba($table-odd-background, 0),
              rgba($table-odd-background, 1) $one-unit * 0.5,
              $table-odd-background
            );
        }
      }
    }

    &:hover {
      @include lvl-transition();
      &:before { // The hover dot
        content: "\2022";
        font-size: 52px;
        color: $secondary-color;
        position: absolute;
        left: -18px;
        top: -16px;
      }

      .cell-content {
        box-shadow: 0 2px 0 0 $secondary-color;
        display: inline-block;
      }
    }

    &--emphasized {
      background: rgba($warning-color, 1) !important; // @todo, rewrite to avoid !important statement here
      &:hover {
        .cell-content {
          background-image:
            linear-gradient(
              to left,
              rgba($warning-color, 0),
              rgba($warning-color, 1) $one-unit * 0.5,
              $warning-color
            ) !important;
        }
      }
    }

  }

  &__cell {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    padding: ($one-unit * 0.75) ($one-unit * 0.5);

    &:first-of-type {
      padding-left: $one-unit * 1.5; // same as the padding provided in @mixin padding-outer-wrapper
    }

    &:last-of-type {
      padding-right: $one-unit * 1.5; // same as the padding provided in @mixin padding-outer-wrapper
    }

  }

  // .tm-table variations
  &.--small-table { // Small tables, currently used for Live Chat in app
    border-radius: $border-radius;
    overflow: hidden;

    .tm-table__header-row {
      border-bottom: 2px solid $primary-color;
    }

    .tm-table__body {
      font-size: 10px; * { font-size: 10px; }
      overflow-wrap: break-word; * { font-size: 10px; }
    }

    .tm-table__cell {
      white-space: normal;

      &:first-of-type {
        padding-left: $one-unit;
      }

      &:last-of-type {
        padding-right: $one-unit;
      }
    }

  }

}

.--single-sales {
  .tm-table {
    .tm-table__row {
      .tm-table__cell:first-child {
        width: 250px;
      }
    }
  }
}

// Specific
.--change-password { // Should be more dynamic/flexible maybe(?)
  min-width: 250px;
}

.--align-right {
  text-align: right;
}
