

/*****************************
Reset/Settings
******************************/
* {
  font-size: $font-size-default;
  font-family: $font-family-default;
  color: $font-color-default;
  line-height: $line-height-default;
}

/*****************************
Styles
******************************/
p:not(:last-child) {
  font-size: $one-unit;
  margin-bottom: $one-unit;
}

h1 {
  font-size: $one-unit * 2;
  margin-bottom: $one-unit;

  * {
    font-size: $one-unit * 2;
  }
}
