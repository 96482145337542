// Reset
body {
  padding: 0;
  margin: 0;
  overflow-y: scroll;
}

// Body/HTML config
body,
html {
  width: 100%;
  height: 100%;
}

/*****************************
Fonts
******************************/

// CircularPro-Book "Main font"
@font-face {
  font-family: "CircularPro";
  src: url("https://storage.googleapis.com/talkmore-public-files/talkmore-styles/fonts/CircularPro-Book.eot?#iefix")
      format("embedded-opentype"),
    url("https://storage.googleapis.com/talkmore-public-files/talkmore-styles/fonts/CircularPro-Book.otf")
      format("opentype"),
    url("https://storage.googleapis.com/talkmore-public-files/talkmore-styles/fonts/CircularPro-Book.woff")
      format("woff"),
    url("https://storage.googleapis.com/talkmore-public-files/talkmore-styles/fonts/CircularPro-Book.ttf")
      format("truetype"),
    url("https://storage.googleapis.com/talkmore-public-files/talkmore-styles/fonts/CircularPro-Book.svg#CircularPro-Book")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

// CircularPro-Bold "Bold font"
@font-face {
  font-family: "CircularPro";
  src: url("https://storage.googleapis.com/talkmore-public-files/talkmore-styles/fonts/CircularPro-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("https://storage.googleapis.com/talkmore-public-files/talkmore-styles/fonts/CircularPro-Bold.otf")
      format("opentype"),
    url("https://storage.googleapis.com/talkmore-public-files/talkmore-styles/fonts/CircularPro-Bold.woff")
      format("woff"),
    url("https://storage.googleapis.com/talkmore-public-files/talkmore-styles/fonts/CircularPro-Bold.ttf")
      format("truetype"),
    url("https://storage.googleapis.com/talkmore-public-files/talkmore-styles/fonts/CircularPro-Bold.svg#CircularPro-Bold")
      format("svg");
  font-weight: bold;
  font-style: normal;
}

// CircularPro-BookItalic "Normal and italic"
@font-face {
  font-family: "CircularPro";
  src: url("https://storage.googleapis.com/talkmore-public-files/talkmore-styles/fonts/CircularPro-BookItalic.eot?#iefix")
      format("embedded-opentype"),
    url("https://storage.googleapis.com/talkmore-public-files/talkmore-styles/fonts/CircularPro-BookItalic.otf")
      format("opentype"),
    url("https://storage.googleapis.com/talkmore-public-files/talkmore-styles/fonts/CircularPro-BookItalic.woff")
      format("woff"),
    url("https://storage.googleapis.com/talkmore-public-files/talkmore-styles/fonts/CircularPro-BookItalic.ttf")
      format("truetype"),
    url("https://storage.googleapis.com/talkmore-public-files/talkmore-styles/fonts/CircularPro-BookItalic.svg#CircularPro-BookItalic")
      format("svg");
  font-weight: normal;
  font-style: italic;
}

// TM Icons
@font-face {
  font-family: "talkmore-icons";
  src: url("https://storage.googleapis.com/talkmore-public-files/talkmore-styles/fonts/talkmore-icons.eot?#iefix")
      format("embedded-opentype"),
    url("https://storage.googleapis.com/talkmore-public-files/talkmore-styles/fonts/talkmore-icons.woff")
      format("woff"),
    url("https://storage.googleapis.com/talkmore-public-files/talkmore-styles/fonts/talkmore-icons.ttf")
      format("truetype"),
    url("https://storage.googleapis.com/talkmore-public-files/talkmore-styles/fonts/talkmore-icons.svg#talkmore-icons")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

// LVL wrapper settings
.lvl-tm {
  &--field-sales {
    height: 100%;
  }

  // Util
  @import "mixins";
  @import "variables";

  // Reset
  @import "reset";

  // Global
  @import "global";

  // Typography
  @import "typography";

  // Forms
  @import "forms";

  // Tables
  @import "tables";

  // Filters
  @import "filters";

  // Add user
  @import "add-user";

  // Menu
  @import "menu";

  // Pagination
  @import "pagination";

  // Cards
  @import "cards";

  // Progress indicator
  @import "progress-indicator";

  // Message buble
  @import "messages";

  // react-redux-toastr
  @import "~react-redux-toastr/src/styles/index";

  &--livechat {
    * {
      font-size: 14px;
    }
  }
}

//
//
//
//
// Specific for live chat app (please move this to separate file)
//
//
//
//

$live-chat-app-font-size: 12px; // This is same size as LiveChat have in their admin so natural for us to use this size as well

.tm-live-chat-app {
  font-size: $live-chat-app-font-size;
  *,
  p {
    font-size: $live-chat-app-font-size;
  }
}

// Helper - section wrapper
.h-section {
  padding: 16px;
}
