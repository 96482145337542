// Filter wrapper
.tm-filters {
  display: flex;
}

// Filter groups
.filter-group {
  display: flex;
  flex-wrap: wrap;
  width: 30%;
  // margin-right: $one-unit * 3; // @todo, should be more dynamic?


  &__header {
    width: 100%;
    margin-bottom: $one-unit * .5;
  }

  &:not(:first-child) {
    flex: 1;
  }

  &:not(:last-child) {
    margin-right: 5%; // @todo, should this value be more dynamic?
  }

  &--vertical {
    flex-direction: column;
    justify-content: space-between;

    > * {
      margin-bottom: $one-unit * .5;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// Date filter specific
.date-filter {
  flex-basis: content;
  align-items: center;

  > div { // The outer wrapper for the datepicker inputs...
    width: 45%;
  }

  &__separator {
    font-size: 180%;
    width: 10% !important;
    text-align: center;
  }

  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      width: 100%;
      input {
        text-align: center;
        padding-left: 4px; // Works as a reset
        padding-right: 4px; // Works as a reset
      }
    }
  }
}
