/*****************************
Whitespace/padding mixins/helpers
******************************/

// Example to get full width: @include padding-outer-wrapper($max-width: none);
@mixin padding-outer-wrapper($top: 0, $max-width: 1024px, $factor: 1.5) {
  padding: $top ($one-unit * $factor) 0;
  max-width: $max-width;
  margin: 0 auto;
}

@mixin padding-vertical-whitespace($padding: $one-unit * 1.5, $add-to-top: true, $add-to-bottom: true) {
  
  @if $add-to-top == true {
    padding-top: $padding; // The reason for not using shorthand is to be able to use this mixin together with padding-outer-wrapper
  }
  @if $add-to-bottom == true {
    padding-bottom: $padding; // The reason for not using shorthand is to be able to use this mixin together with padding-outer-wrapper
  }
}

// Vertical Whitespace
// --- $factor ---    
// --- $property ---  CCC property - padding or margin? 
// --- $top ---     Add this whitespace to top?
// --- $bottom ---    Add this whitespace to bottom?
@mixin whitespace-vertical($factor: 4, $property: padding, $top: true, $bottom: true) {
  $padding-size: ($global-margin / 1.7) * $factor;
  
  // "Default"
  #{$property}-top: $padding-size;
  #{$property}-bottom: $padding-size;

  // @include breakpoint(xlarge) {
  //   $padding-size: $padding-size * 1.25;
  //   #{$property}-top: $padding-size;
  //   #{$property}-bottom: $padding-size;   
  // }
  
  // No top or no bottom
  @if $top == false {
    #{$property}-top: 0 !important;
  } @else if $bottom == false {
    #{$property}-bottom: 0 !important;
  }
  
}

//////////////////////////////////  
// UX - Transitions
//////////////////////////////////
$transition-duration-default: .15s;
$transition-timing-function-default: ease;

// Transitions
@mixin lvl-transition($property: all, $duration: $transition-duration-default, $timing-function: $transition-timing-function-default) {
  transition: $property $duration $timing-function;
}