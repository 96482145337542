@import "mixins";
@import "variables";

// @todo, consider renaming _wrapper classes (classes where this is a postfix)
// @todo, most or many of these globals are actually styles for (and only) used in Field Sales

/*****************************
Icon
******************************/
// Icon util
.icon {
  font-family: 'talkmore-icons';
  speak: none;
}

// Position: icon to left or right?
// $Content: Unicode of the icon
@mixin add-icon-to-button($content, $position: 'left') {
  @if $position == 'left' {
    &:before {
      @extend .icon;
      padding-right: $one-unit / 2;
      margin-left: -($one-unit / 4); // Fake alignment
      content: $content;
    }
  } @else {
    &:after {
      @extend .icon;
      padding-left: $one-unit / 2;
      margin-right: -($one-unit / 4); // Fake alignment
      content: $content;
    }
  }
}

// Position: icon to left or right?
// $Content: Unicode of the icon
@mixin add-icon-to-ui($content, $position: 'left') {
  @if $position == 'left' {
    &:before {
      @extend .icon;
      padding-right: $one-unit / 1.5;
      color: $warning-color; // Should be dynamic so we could use mixin in more cases
      content: $content;
    }
  } @else {
    &:after {
      @extend .icon;
      padding-left: $one-unit / 1.5;
      color: $warning-color; // Should be dynamic so we could use mixin in more cases
      content: $content;
    }
  }
}

/*****************************
temp for svg in header, @todo maybe move from here
******************************/
.talk_part_logo {
  fill: $primary-color;
}

.more_part_logo {
  fill: $secondary-color;
}

.tm-logo svg {
  height: $one-unit * 2;
  width: auto;
}

/*****************************
Lightbox
******************************/
.lightbox_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  background: $tertiary-color;
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
}

.lightbox_content {
  width: $overlay-width;
  padding: $overlay-padding;
  background: $white;
  position: relative;

  button {
      width: 100%;
  }

  h1 {
    font-size: $one-unit * 1.5;
  }

  &__close-button {
    position: absolute;
    top: $one-unit;
    right: $one-unit;
  }
}

/*****************************
Temp util @todo, maybe move to separate file
******************************/
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

/*****************************
A
******************************/
a {
  &.disabled {
    pointer-events: none;
    cursor: auto;
  }
}

/*****************************
Buttons
******************************/

button:not(.react-datepicker__navigation) {
  @extend .noselect;
  border: none; // Reset

  color: $font-color-default;
  background: $secondary-color;
  border-radius: $border-radius;

  padding: $button-padding;
  font-weight: bold;
  white-space: nowrap;

  // @todo only serve transitions if input is mouse
  @include lvl-transition($property: background);
  &:hover {
    @include lvl-transition($property: background);
  }
  // end @todo

  // States
  &:hover:not([disabled]) {
    cursor: pointer; // Not sure why we need to set this actually
    background: darken($secondary-color, 20%);
  }

  &:hover { // @todo, test if this is actually active
    background: darken($secondary-color, 20%);
  }

  &[disabled] {
    color: $font-color-default; // "Reset"
    opacity: .25;
  }
  // Variations
  &.secondary {
    border: 2px solid $primary-color;
    background: $white;

    &:hover {
      background: $primary-color;
      color: $white;
    }
  }

  &.large {
    padding: 28px 45px;
    font-size: 18px;
  }

  &.attached-button { // temp
    padding: 13px 20px;; // 15px 25px;
    font-size: 16px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.small-button {
    padding: 13px 20px; // 15px 25px;
    font-size: 16px;
  }

  &.tiny-button {
    padding: 8px 12px; // 15px 25px;
    font-size: 13px;
  }

  &.--transparent {
    background: rgba(0,0,0,0);
  }

  &.--approve,  &.--notnow, &.--reject { // Used in Livechat in-app-app
    text-transform: uppercase;
    letter-spacing: 1px;
    background: none;
    white-space: normal;
    border: 2px solid $gray-light;

    &:before {
      @extend .icon;
      width: 100%;
      display: block;
      border-radius: 100px;
      width: 30px;
      height: 30px;
      line-height: 30px;
      margin: 0 auto;
      margin-bottom: $one-unit * .25;
    }

    &:hover {
      background: none;
      border: 2px solid $secondary-color;
    }

  }

  &.--approve {
    &:before {
      content: '\f012';
      background: $secondary-color;
    }

    &:hover {
      border-color: $secondary-color;
    }
  }

  &.--notnow {
    &:before {
      content: '\f021';
      background: $warning-color;
    }

    &:hover {
      border-color: $warning-color;
    }
  }

  &.--reject {
    &:before {
      content: '\f00c';
      background: $alert-color;
    }

    &:hover {
      border-color: $alert-color;
    }
  }




  &.btn-danger { // @todo, rename class
    background: $alert-color;
    color: $white;
    @include add-icon-to-button("\f029", 'left');
    &:hover {
      background: darken($alert-color, 4%);
    }
  }
}

.button-group {
  display: flex;
}

/*****************************
Project Globals
******************************/

// Body color logged in
.logged-in {
  background: $gray-lightest;

  &.--fieldsales-admin {
    background: none;
  }
}

// Body color NOT logged in
.not-logged-in {
  background: linear-gradient($tertiary-color 50%, $white 50%);
  &.--fieldsales-admin {
    background: linear-gradient($primary-color 50%, $white 50%);
  }
}

.app_wrapper, .app {
  height: 100%;
}

.app {
  position: relative;
}

.anon {
  height: 100%;
}

.anon_inner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .form_wrapper {
    width: $overlay-width;
    padding: $overlay-padding;
    background: $gray-lightest;

    text-align: center;
    svg { // logo
      margin-bottom: $one-unit * 2;
    }

    .sub-brand {
      margin-top: -($one-unit * 1.75);
      margin-bottom: $one-unit * 1.75;
    }

    button {
      width: 100%;
    }

    .input_wrapper:last-of-type { // @todo, not very good approach, because its not gonna work if one more div gets added or similar.
      margin-bottom: $one-unit * 2;
    }

  }
}


.step__main {
  background: $white;
  padding: ($one-unit * 2) 0; // sideways padding from section
}

.step__main__inner {
  @include padding-outer-wrapper();
}

.step__footer_wrapper { // temp hack??
  background: $gray-lightest;
}

.step__footer {
  @include padding-outer-wrapper();
  @include padding-vertical-whitespace();

  background: $gray-lightest;
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: $one-unit / 2;
  }
}

.site-header {
  background: $gray-lightest;

  &__inner {
    @include padding-outer-wrapper();
    @include padding-vertical-whitespace();

    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  // Used for field sales admin
  &--alternative {
    background: $tertiary-color;
    .site-header__inner {
      @include padding-outer-wrapper($max-width: none);
      @include padding-vertical-whitespace();
    }
  }
}

/*****************************
Main
******************************/
.main-header {
  background: $gray-lightest;

  @include padding-outer-wrapper($max-width: none);
  @include padding-vertical-whitespace();

  h1 {
    margin: 0; // @todo, move this out from here
  }

  &.main-header--dark {
    background: none;
    display: inline-block;
    margin-left: 10px;
    border-radius: 0 0 10px 10px;
  }
}

.main-body {
  $main-body-margin: 0 ($one-unit * 1.5); // Please note this is the same for the whole page (TM Admin) - so this value is copied from somewhere

  display: flex;
  flex-wrap: wrap;
  @include whitespace-vertical();

  &:not(:first-child) {
    flex: 1;
  }

  > * {
    width: 100%;
  }

  &:not(.--wide-layout-temp-class) {
    @media (min-width: 1000px) { // temp query, should be more dynamic and accurate. Used in offices details at the moment
      > * {
        width: 50%;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: $main-body-margin;
    @include padding-vertical-whitespace($add-to-top: false);

    > * {
      margin-bottom: 0;
    }

    .emphasized {
      color: $secondary-color;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end; // Probably temp. As only the sales list have main body footer for no
    margin: $main-body-margin;
    @include padding-vertical-whitespace($add-to-bottom: false);

  }

}


.tm-section-card {
  padding: ($one-unit * 1.5); // same as padding-outer-wrapper

  &__data {
    border: 2px solid $gray-lightest;
    border-radius: $border-radius;
    overflow: hidden;

  }

}

/*****************************
Build your own buttons
******************************/
/*

Build your own buttons
Example:

.my_button {
  @extend .secondary;
  @extend .large;
  @include add-icon-to-button("\f092", 'left');
}

*/


// "App specific" button classes
.login {
  @extend .large;
  @include add-icon-to-button("\f03a", 'right');
}

.next {
  @extend .large;
  @include add-icon-to-button("\f095", 'right');
}

.prev {
  @extend .secondary;
  @extend .large;
  @include add-icon-to-button("\f092", 'left');
}

.submit {
  @extend .large;
  @include add-icon-to-button("\f012", 'right');
}

.empty {
  @extend .secondary;
  @include add-icon-to-button("\f029", 'right');
}

.logout {
  @extend .secondary;
  @include add-icon-to-button("\f04a", 'right');
}

.clear, .restart {
  @include add-icon-to-button("\e901", 'left');
}

.search {
  @include add-icon-to-button("\f002", 'left');
  width: 100%; // temp
  margin-bottom: $one-unit;
}

.save {
  @include add-icon-to-button("\f012", 'left');
}

.edit-item {
  @extend .secondary;
  @include add-icon-to-button("\f024");
}

.delete-item {
  @extend .secondary;
  @include add-icon-to-button("\f029");
}

/*****************************
Misc
******************************/
button + button {
  margin-left: $one-unit / 2;
}

.header_wrapper {
  margin-bottom: $one-unit;

  h1 {
    margin-bottom: 0; // Reset
  }
}

// UI Infobox
.infobox {
  border-top: 2px solid $warning-color;
  border-bottom: 2px solid $warning-color;
  padding: ($one-unit / 1.5) 0;
  margin-bottom: $one-unit;

  @include add-icon-to-ui("\f063", 'left');
}

// Callout / Box
.callout {
  background: $gray-lightest;
  padding: $one-unit * 2;
  padding-top: $one-unit * 1.5;
  margin: ($one-unit / 2) 0;

  .input_wrapper:last-of-type {
    margin-bottom: 0; // Temp hack?
  }
}

// Definition lists
dl {
  dt {
    display: inline-block;
  }
  dd {
    margin-bottom: $one-unit / 2;
  }
}

/*****************************
Signatur Pad
******************************/
.m-signature-pad--body {
  border: 2px solid $primary-color;
  margin-bottom: $one-unit;
}

.m-signature-pad {
  margin-bottom: $one-unit;
}

.m-signature-pad--footer {
  display: inline-block;
}

/*****************************
Img
******************************/
img {
  max-width: 100%;
}

/*****************************
Links
******************************/
a {
  &:hover {
    cursor: pointer;
  }

  &:visited, &:active {
    color: $primary-color;
  }
}

/*****************************
Loading in UI // TEMP STYLES
******************************/

.tm-table.h-has-loader {
  .tm-table__header-row {
    opacity: .2;
    @include lvl-transition($property: opacity, $duration: 0.4s);
  }

  .tm-table__body {
    opacity: .2;
    @include lvl-transition($property: opacity, $duration: 0.4s);
  }

  &.is-done-loading {
    .tm-table__header-row {
      opacity: 1;
      @include lvl-transition($property: opacity, $duration: 0.4s);
    }

    .tm-table__body {
      opacity: 1;
      @include lvl-transition($property: opacity, $duration: 0.4s);
    }
  }
}

.h-has-loader {
  display: block;
  height: 100%;
  width: 100%;
  position: relative;
  min-height: 200px; // Can we have this dynamic?

  .lds_w {
    display: flex;
    justify-content: center;
    align-items: center;

    // z-index: 800;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255,255,255,.7);

    @include lvl-transition($property: opacity, $duration: 0.5s);
  }

  // Loading done
  &.is-done-loading {
    .lds_w {
      @include lvl-transition($property: opacity, $duration: 0.5s);
    }

    .lds-ellipsis {
      display: none;
    }

  }
  // END Loading done

  .lds-ellipsis {
    display: inline-block;
  }

}

// Loader itself
// Source: https://loading.io/css/
.lds-ellipsis {
  // display: none;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: $primary-color;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

// Temp (adminpanel specific)
.tm-logo, .tm-logo-link {
  text-decoration: none !important;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1.5px;
}

.sub-brand {
  font-size: 70%;
}

.tm-screenshot {
  overflow: hidden;
  border-radius: $border-radius;
  border: 2px solid $primary-color;
  margin-bottom: $one-unit;
}

.extra-abroad-country {
  p {
    margin-bottom: 4px;
  }
}

.fieldsales-overlay {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(200, 200, 200, 0.9);
  > div {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 10%;
    left: 10%;
    bottom: 10%;
    right: 10%;
    @media (max-width: 720px) {
      top: 5%;
      left: 5%;
      bottom: 5%;
      right: 5%;
    }
    background-color: #f8f9fa;
    padding: 4rem;
    box-shadow: 5px 5px 10px #888;
    border-radius: 10px;
    @media (max-width: 720px) {
      padding: 1.5rem;
    }
    h3 {
      font-size: 34px;
      @media (max-width: 720px) {
        font-size: 20px;
      }
    }
    p {
      font-size: 22px;
      @media (max-width: 720px) {
        font-size: 16px;
      }
    }
    > div {
      &:first-child {
        margin-bottom: 3rem;
      }
    }
  }
  .toggles {
    flex: 1;
    > div {
      > div:first-child {
        display: flex;
        > div:first-child {
          display: flex;
          align-items: center;
          flex: 1;
          font-size: 22px;
          @media (max-width: 720px) {
            font-size: 16px;
          }
        }
        > div:last-child {
          display: flex;
          @media (max-width: 720px) {
            display: block;
            margin-left: 0.5rem;
            > div:first-child {
              margin-bottom: 1rem;
            }
          }
        }
      }
      > div:nth-child(2) {
        margin-top: 1rem;
        p {
          font-size: 16px;
          margin-bottom: 1rem;
          @media (max-width: 720px) {
            font-size: 12px;
          }
        }
      }
      margin-bottom: 2rem;
      padding: 1rem;
      border: 1px solid #ddd;
      border-radius: 10px;
      background-color: #fff;
    }
    .oh-yeah, .oh-noes {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 50px;
      border-radius: 10px;
      color: #fff;
      font-size: 18px;
      font-weight: 800;
      border: 1px solid #aaa;
      color: #aaa;
      @media (max-width: 720px) {
        font-size: 12px;
        width: 70px;
        height: 30px;
      }
    }
    .oh-yeah {
      margin-right: 0.5rem;
      border-color: #81d058;
      color: #81d058;
      @media (max-width: 720px) {
        margin-right: 0;
      }
      &.active {
        border-color: #a1d078;
        background-color: #a1d078;
        color: #000e4e;
      }
    }
    .oh-noes {
      margin-left: 0.5rem;
      color: #e09198;
      border-color: #e09198;
      @media (max-width: 720px) {
        margin-left: 0;
      }
      &.active {
        border-color: #e09198;
        background-color: #e09198;
        color: #000e4e;
      }
    }
  }
  .buttons {
    display: flex;
    > * {
      padding: 2rem 0;
    }
  }
}

.buttons {
  display: flex;
  > * {
    display: flex;
    justify-content: center;
    flex: 1;
    // padding: 2rem 0;
    border-radius: 10px;
  }
  .cancel {
    margin-right: 1rem;
    background-color: #fff;
    border: 1px solid #aaa;
  } 
  .continue {
    margin-left: 1rem;
    background-color: #a1d078;
    color: #000e4e;
  }   
}
